class sitSchedule {

    _selector = {
        parrent: '',
        fakeDot: '.dot__item',
        active: '[data-index]',
    }

    splideOptions = {
        pagination: false,
        arrows: true,
    }

    splide = null;

    constructor(schedule) {
        this._selector.parrent = schedule;
        this.initSplide();
        this.listenDot();
    }

    initSplide(){
        // Init du slider
        if (this._selector.parrent.classList.contains("splide")){
            this.splide = new Splide(this._selector.parrent).mount();
        }
    }

    listenDot(){
        this._selector.parrent.querySelectorAll(this._selector.fakeDot).forEach((e) => {
            e.addEventListener('click', (event) => {
                const index = e.getAttribute('data-index');
                if (index){
                    this.changeActive(index);
                }
            });
        });
    }

    changeActive(index){
        this._selector.parrent.querySelectorAll(this._selector.active).forEach((e) => {
            if (e.classList.contains('active')){
                if (e.getAttribute('data-index') != index) {
                    e.classList.remove('active');
                }
            }

            if (e.getAttribute('data-index') == index){
                e.classList.add('active');
            }
        });
    }

}

(function () {
    let sitScheduleArray = [];

    const sitScheduleSelector = document.querySelectorAll('.js-slider-sit-horaires');
    sitScheduleSelector.forEach(schedule => {
        let newSitSchedule = new sitSchedule(schedule);
        sitScheduleArray.push(newSitSchedule);
    });

})();